











































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
  },

  setup(_, { root }) {
    const form = ref<any>(null);

    const model = reactive({
      name: "",
      text: "",
    });

    const state = reactive({
      dialog: false,
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      valid: false,
      limitPerSMS: 160,
      smsCount: 0,
      shortSMS: false,
    });

    const smsId = computed(() => {
      if (root.$route.params.smsid) {
        return root.$route.params.smsid;
      } else null;
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`sms-template/${smsId.value}`)
        .then(({ data }) => {
          model.name = data.name;
          model.text = data.text;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const countSMSlength = () => {
      const specialChars = ["ą", "ę", "ś", "ć", "ń", "ż", "ź", "ó", "ł"];
      const smsLength = model.text.length;
      for (const t in specialChars) {
        if (
          model.text.toLowerCase().indexOf(specialChars[t].toLowerCase()) >= 0
        ) {
          state.limitPerSMS = 70;
          state.shortSMS = true;
        }
      }
      state.smsCount = Math.ceil(smsLength / state.limitPerSMS);
    };

    watch(() => model.text, countSMSlength);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name || undefined,
        text: model.text || undefined,
      };

      state.loading = true;

      const succesInfo = () => {
        root.$store.commit("snackbar/PUSH_MESSAGE", {
          id: uuid(),
          color: "primary",
          message: root.$tc("panel.event.abstract.config.mails.save"),
        });
      };

      if (smsId.value) {
        axiosInstance
          .put(`sms-template/${smsId.value}`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;
            succesInfo();
            setTimeout(() => {
              root.$router.back();
            }, 500);
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => {
            state.loading = false;
          });
      } else {
        axiosInstance
          .post(`event/${root.$route.params.id}/sms-template/`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;
            succesInfo();
            setTimeout(() => {
              root.$router.back();
            }, 500);
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => {
            state.loading = false;
          });
      }
    };

    const rules = {
      required: [(v: string) => !!v || root.$t("layout.misc.required")],
    };

    return {
      form,
      model,
      state,
      onSubmit,
      rules,
    };
  },
});
